import Vue from 'vue';
import App from './App.vue';
import './css/bootstrap/bootstrap.scss';
import './css/app.scss';
import './js/registerServiceWorker';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import messages from './assets/i18n/translations';
import router from './js/routes';
import VueTheMask from 'vue-the-mask'
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;

Vue.use(VueTheMask)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en-GB',
  fallbackLocale: 'pl-PL',
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
