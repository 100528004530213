//Test

const Config = {

    //domain: 'https://medi.test',
    //apiUrl: 'https://medi.test/api',

    domain: 'https://medica-en.ec4.dev',
    apiUrl: 'https://medica-en.ec4.dev/api',

}


export default Config;
